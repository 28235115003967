export enum AmlInspectionStatus {
  Unknown = 0,
  Initiated = 10,
  Pending = 15,
  Verified = 20,
  Rejected = 30,
  RejectedWithRetry = 40
}

export type CompanyFormType = {
  countryId: number
  companyName: string
  bankId: number
  accountNumber: string
  notificationEmail: string
  isActive: boolean
}

export type CompanyDetails = CompanyFormType & {
  id: number
  amlInspectionStatus: AmlInspectionStatus
}

export type UpdateCompanyRequestBody = {
  bankId: number,
  accountNumber: string,
  notificationEmail: string,
  isActive: boolean
}

export type UpdateInstanceCompanyArg = {
  body: UpdateCompanyRequestBody,
  companyId: string | number
}

export type GetAmlAccessTokenForCompanyResponse = {
  token: string
}

export type ValidateCompanyAccNumberRequestQuery = {
  accountNumber: string
  bankId: string | number
}

export type ValidateCompanyAccNumberResponse = {
  holderName: string
  currency: string
  isValid: boolean
  errorDescription: string
}


