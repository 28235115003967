import { authorizedRequest } from '../api/authorizeRequest'
import { CompanyDetails, CompanyFormType, GetAmlAccessTokenForCompanyResponse, UpdateCompanyRequestBody, UpdateInstanceCompanyArg, ValidateCompanyAccNumberRequestQuery, ValidateCompanyAccNumberResponse } from '../types/companiesTypes'


const getCompanyList = () => {
  const path = ['company']

  return authorizedRequest<null, Array<CompanyDetails>>(path, 'GET')
}

const getCompanyById = (companyId: number) => {
  const path = ['company', `${companyId}`]

  return authorizedRequest<null, CompanyDetails>(path)
}

const addCompany = (body: CompanyFormType) => {
  const path = ['company']

  return authorizedRequest<CompanyFormType, null>(path, 'POST', body)
}

const updatedCompany = (arg: UpdateInstanceCompanyArg) => {
  const { companyId, body } = arg
  const path = ['company', `${companyId}`]


  return authorizedRequest<UpdateCompanyRequestBody, null>(path, 'PUT', body)
}

const getAmlAccessTokenForCompany = (companyId: number) => {
  const path = ['company', `${companyId}`, 'aml-checks', 'access-token']

  return authorizedRequest<null, GetAmlAccessTokenForCompanyResponse>(path)
}

function validateCompanyAccNumber(requestQuery: ValidateCompanyAccNumberRequestQuery) {
  const path = ['company', 'validate-bankaccount']

  const query = {
    accountNumber: requestQuery.accountNumber,
    bankId: requestQuery.bankId,
  }

  return authorizedRequest<null, ValidateCompanyAccNumberResponse>(path, 'GET', null, query)
}

export const companiesServices = {
  getCompanyList,
  getCompanyById,
  addCompany,
  updatedCompany,
  getAmlAccessTokenForCompany,
  validateCompanyAccNumber,
}
