import { useCallback, useState } from 'react'
import { openGlobalModal } from '../../../store/reducers/globalModalSlice'
import { ClickAwayListener, styled } from '@mui/material'
import Popper from '@mui/material/Popper'
import Fade from '@mui/material/Fade'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import { useAppDispatch } from '../../../hooks/redux'
import { companiesActionCreators } from '../../../store/actionCreators/companiesActionCreators'

const StyledTableMenuButton = styled('div')({
	position: 'relative',
})

const StyledButton = styled(Button)(({ theme }) => ({
	whiteSpace: 'nowrap',
	color: '#3171D8',
	textTransform: 'initial',
	borderRadius: 0,
	'&:hover': {
		background: theme.palette.secondary.dark,
	},
}))

const StyledMenuButton = styled('button')({
	display: 'flex',
	justifyContent: 'space-around',
	alignItems: 'center',
	width: '35px',
	height: '35px',
	padding: '5px',
	// backgroundColor: rowIndex % 2 ? '#EFF6FF' : '#FFFFFF',
	// backgroundColor: '#FFFFFF',
	borderRadius: '50%',
	border: 'none',
	'&:hover': {
		cursor: 'pointer',
	},
	div: {
		width: '4px',
		height: '4px',
		borderRadius: '50%',
		backgroundColor: '#3171D8',
	},
})

type CompaniesTableMenuButtonProps = {
	companyId: number
}

const CompaniesTableMenuButton = ({ companyId }: CompaniesTableMenuButtonProps) => {
	const dispatch = useAppDispatch()
	const [open, setOpen] = useState(false)
	const [arrowRef, setArrowRef] = useState<null | HTMLElement>(null)
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

	const handleClick = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget)
		setOpen(previousOpen => !previousOpen)
	}

	const onClickAway = () => {
		setOpen(false)
		setAnchorEl(null)
	}

	const openEditCompanyModal = useCallback(async () => {
		dispatch(companiesActionCreators.getCompanyById(companyId))
			.unwrap()
			.then(() => dispatch(openGlobalModal({ variant: 'edit-company' })))
	}, [companyId, dispatch])

	return (
		<ClickAwayListener onClickAway={onClickAway}>
			<StyledTableMenuButton onClick={handleClick}>
				<>
					<StyledMenuButton className="btn-more">
						<div></div>
						<div></div>
						<div></div>
					</StyledMenuButton>

					<Popper
						sx={{ position: 'relative' }}
						open={open}
						anchorEl={anchorEl}
						disablePortal={true}
						placement="left-start"
						modifiers={[
							{
								name: 'arrow',
								enabled: true,
								options: {
									element: arrowRef,
								},
							},
						]}
						transition
					>
						{({ TransitionProps }) => (
							<>
								<Fade {...TransitionProps} timeout={350}>
									<Box
										sx={{
											marginRight: '10px',
											display: 'flex',
											flexDirection: 'column',
											border: '1px solid #3171D8',
											bgcolor: '#FFF',
										}}
									>
										<StyledButton variant="text" onClick={openEditCompanyModal}>
											Edit
										</StyledButton>
									</Box>
								</Fade>
							</>
						)}
					</Popper>
				</>
			</StyledTableMenuButton>
		</ClickAwayListener>
	)
}

export default CompaniesTableMenuButton
