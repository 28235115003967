import { styled } from '@mui/material'
import { LegacyRef, useMemo } from 'react'
import { TableConfig } from '../../../types/tableTypes'
// import useScrollBar from '../../../hooks/useScrollBar'
import TableRow from './TableRow'
import CircularProgress from '@mui/material/CircularProgress'
import CompaniesTableHeader from './CompaniesTableHeader'

type CompaniesTableProps<T> = {
	tableConfig: TableConfig<T>
	tableData: T[]
	onClick?: (id: string | number) => void
	sentryRef: LegacyRef<HTMLDivElement>
	rootRef: LegacyRef<HTMLDivElement>
	// minTableWidth: string
	loading: boolean
	hasNextPage: boolean
}

const StyledLoading = styled('div')(({ theme }) => ({
	display: 'flex',
	justifyContent: 'center',
	padding: '20px 0',
}))

const StyledCompaniesTable = styled('div')(
	({ theme }) => ({
		minWidth: '355px',
		height: 'calc(100vh - 260px)',
		'.table-wrap': {
			overflowX: 'auto',
			overflowY: 'auto',
			// height: 'calc(100vh - 260px)',
			height: '100%',
			'&': {
				scrollbarWidth: 'auto',
				scrollbarColor: 'rgba(0, 0, 0, 0.4) #ffffff',
			},

			'&::-webkit-scrollbar': {
				width: '16px',
			},

			'&::-webkit-scrollbar-track': {
				background: '#ffffff',
			},

			'&::-webkit-scrollbar-thumb': {
				backgroundColor: 'rgba(0, 0, 0, 0.4)',
				borderRadius: '10px',
				border: '3px solid #ffffff',
			},
		},
		'.table-header': {
			position: 'sticky',
			top: '2',
			zIndex: 10,
			display: 'grid',
			gridTemplateColumns:
				'minmax(260px, 1fr) minmax(200px, 1fr) minmax(220px, 1fr) minmax(210px, 1fr) minmax(100px, 1fr) minmax(60px, 70px)',
        // 'minmax(260px, 1fr) minmax(200px, 1fr) minmax(220px, 1fr) minmax(210px, 1fr) minmax(100px, 1fr) minmax(60px, 1fr)',
		},
		'.table-body': {
			// height: '400px',
			// maxHeight: '425px',
			// height: '100%',
			// maxHeight: '100%',
		},
		[theme.breakpoints.down('md')]: {
			height: 'calc(100vh - 310px)',
		},
		[theme.breakpoints.down('sm')]: {
			height: 'calc(100vh - 340px)',

			'.table-header': {
				gridTemplateColumns:
					'minmax(160px, 1fr) minmax(120px, 1fr) minmax(170px, 1fr) minmax(170px, 1fr) minmax(90px, 1fr) minmax(60px, 70px)',
			},
		},
	}),
)

const CompaniesTable = <T extends { extId?: string; id?: string | number }>({
	tableConfig,
	tableData,
	rootRef,
	sentryRef,
	onClick,
	// minTableWidth,
	loading,
	hasNextPage,
}: CompaniesTableProps<T>) => {
	// const tableBodyRef = useRef<HTMLDivElement | null>(null)
	// const tableRef = useRef<HTMLDivElement | null>(null)

	// const hasScroll = true

	// useScrollBar(tableBodyRef, hasScroll)
	// useScrollBar(tableRef, hasScroll)
	const isExistOnClick = useMemo(() => Boolean(onClick), [onClick])

	return (
		<StyledCompaniesTable>
			<div className="table-wrap" ref={rootRef}>
				<CompaniesTableHeader tableConfig={tableConfig} />
				<div
					className="table-body"

					// style={{ height: hasScroll ? '435px' : 'auto' }}
				>
					{tableData.map((rowData, rowIndex) => (
						<TableRow<T>
							// sentryRef={sentryRef}
							key={rowData.extId || rowData.id}
							rowData={rowData}
							rowIndex={rowIndex}
							tableConfig={tableConfig}
							isExistOnClick={isExistOnClick}
							onClick={onClick}
						/>
					))}
					{(loading || hasNextPage) && (
						<StyledLoading ref={sentryRef}>
							<CircularProgress />
						</StyledLoading>
					)}
				</div>
			</div>
		</StyledCompaniesTable>
	)
}

export default CompaniesTable
