import { useMemo } from 'react'
import { closeGlobalModal } from '../../store/reducers/globalModalSlice'
import { useAppSelector } from '../../hooks/redux'
import DialogModal from './DialogModal'
import CreateRefLinkModal from './CreateRefLinkModal'
import DeleteRefLinkModal from './DeleteRefLinkModal'
import SetDefaultRewardFeesModal from '../referralLinks/modal/SetDefaultRewardFeesModal'
import EditingCustomerDetailsModal from '../customers/modal/EditingCustomerDetailsModal'
import CompanyModal from '../companies/modal/CompanyModal'

const CustomModal = () => {
	const { isModalOpen: isOpenDialog, variant } = useAppSelector(
		state => state.globalModal,
	)

	const currentModal = useMemo(() => {
		if (variant === 'edit-customer-details') {
			return (
				<EditingCustomerDetailsModal
					isOpenDialog={isOpenDialog}
					onCloseModalClick={closeGlobalModal}
				/>
			)
		}
		if (variant === 'block-customer') {
			return (
				<DialogModal isOpenDialog={isOpenDialog} onCloseModalClick={closeGlobalModal} />
			)
		}
		// if (variant === 'invite-new') {
		// 	return <InviteNewModal />
		// }
		if (variant === 'create-ref-link') {
			return (
				<CreateRefLinkModal
					isOpenDialog={isOpenDialog}
					onCloseModalClick={closeGlobalModal}
				/>
			)
		}
		if (variant === 'delete-ref-link') {
			return (
				<DeleteRefLinkModal
					isOpenDialog={isOpenDialog}
					onCloseModalClick={closeGlobalModal}
				/>
			)
		}

		if (variant === 'set-default-reward-fees') {
			return <SetDefaultRewardFeesModal isOpenDialog={isOpenDialog} />
		}

		if (variant === 'add-company' || variant === 'edit-company') {
			return <CompanyModal variant={variant} isOpenDialog={isOpenDialog} />
		}
	}, [isOpenDialog, variant])

	return <>{currentModal}</>
}

export default CustomModal
