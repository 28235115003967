import React, { useCallback, useState } from 'react'
import { Navigate, Outlet, useLocation } from 'react-router-dom'
import { localStorageData } from '../../helpers/localStorage'
import { useAppSelector } from '../../hooks/redux'
import Header from './Header'

import Main from './Main'
import SideBar from './sideBar/SideBar'
import { isKwlMAPermission } from '../../helpers/utils/auth'

const ProtectedRoute = () => {
	const token = localStorageData.getData('authData')
	const userDetails = useAppSelector(state => state.auth.userDetails)
	const userPermission = useAppSelector(state => state.auth.userPermission)
	const loggedIn = token && userDetails && userPermission.length > 0
	const location = useLocation()

	const [isOpenMenu, setIsOpenMenu] = useState(false)

	const onToggleMenu = () => {
		setIsOpenMenu(prevOpen => !prevOpen)
	}

	const MainContent = useCallback(() => {
		if (!isKwlMAPermission(userPermission) && location.pathname === '/companies') {
			return <Navigate to="/referral-links" />
		}

    return <Outlet/>
	}, [location.pathname, userPermission])

	if (loggedIn) {
		return (
			<>
				<Header isOpenMenu={isOpenMenu} onToggleMenu={onToggleMenu} withSideBar />
				<SideBar isOpenMenu={isOpenMenu} onToggleMenu={onToggleMenu} />
				<Main>
					<MainContent />
				</Main>
			</>
		)
	} else if (!token) {
		return <Navigate to="/login" />
	} else {
		return null
	}
}

export default ProtectedRoute
