import {
	StyledCloseButtonModal,
	StyledDialog,
	StyledDialogActions,
	StyledDialogBody,
	StyledDialogContent,
	StyledDialogHeader,
	StyledDialogTitle,
} from '../../modal/styledModalComponent'
import CancelIcon from '@mui/icons-material/Cancel'
import { useAppDispatch, useAppSelector } from '../../../hooks/redux'
import Paper from '@mui/material/Paper'
import Grid from '@mui/material/Grid'
import { CustomButton, SecondaryButton } from '../../ui/CustomButton'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { closeGlobalModal } from '../../../store/reducers/globalModalSlice'
import { CircularProgress, styled } from '@mui/material'
import CompanyForm from './CompanyForm'
import {
	clearAccessTokenForAmlCheckingCompany,
	clearCompanyDetails,
} from '../../../store/reducers/companiesSlice'
import { companiesActionCreators } from '../../../store/actionCreators/companiesActionCreators'
import { CompanyFormType } from '../../../types/companiesTypes'
import { omit } from 'lodash'
import AmlCheckingSection from './AmlCheckingSection'

const StyledLoading = styled('div')(({ theme }) => ({
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	position: 'fixed',
	zIndex: 1301,
	top: 0,
	left: 0,
	bottom: 0,
	right: 0,
	width: '100%',
	backgroundColor: 'rgba(255, 255, 255, 0.6)',
}))

const StyledCompanyModal = styled(StyledDialog)({
	'.MuiDialog-paper': {
		width: '550px',
		'.MuiDialogTitle-root': {
			width: 'unset',
		},
	},
})

type CompanyModalProps = {
	variant: 'add-company' | 'edit-company'
	isOpenDialog: boolean
}

const CompanyModal = ({ isOpenDialog, variant }: CompanyModalProps) => {
	const dispatch = useAppDispatch()

	const { getCompanyDetailsLoadState } = useAppSelector(state => state.companies)
	const { validateCompanyAccNumberLoadState } =
		useAppSelector(state => state.companies)

	const [isAmlCheckingSection, setIsAmlCheckingSection] = useState(false)
	const [isDisabledBtn, setIsDisabledBtn] = useState(false)
	const [isValidAccNumberField, setIsValidNumberField] = useState(false)

	const headerText = useMemo(() => {
		if (variant === 'add-company') {
			return 'Adding Company'
		} else {
			return 'Editing Company'
		}
	}, [variant])

	const onAmlCheckingBtn = useCallback(() => {
		setIsAmlCheckingSection(true)
	}, [])

	useEffect(() => {
		return () => {
			dispatch(clearCompanyDetails())
		}
	}, [dispatch])

	const closeModal = () => {
		dispatch(closeGlobalModal())
		setIsAmlCheckingSection(false)
		dispatch(clearAccessTokenForAmlCheckingCompany())
		dispatch(companiesActionCreators.getCompanyList())
	}

	const onSave = async (dataForm: CompanyFormType, companyId?: string | number) => {
		if (variant === 'add-company') {
			dispatch(companiesActionCreators.addCompany(dataForm))
				.unwrap()
				.then(() => {
					setIsAmlCheckingSection(true)
				})
		}
		if (variant === 'edit-company') {
			const omittedData = omit(dataForm, ['companyName', 'countryId'])
			const arg = {
				body: omittedData,
				companyId: companyId || 0,
			}

			dispatch(companiesActionCreators.editCompany(arg))
			closeModal()
		}
	}

	if (variant === 'edit-company' && !getCompanyDetailsLoadState.isLoaded) {
		return null
	}

	return (
		<>
			{validateCompanyAccNumberLoadState.isLoading && (
				<StyledLoading>
					<CircularProgress />
				</StyledLoading>
			)}
			<StyledCompanyModal open={isOpenDialog} onClose={closeModal}>
				<StyledDialogHeader>
					<StyledDialogTitle>{headerText}</StyledDialogTitle>
					<StyledCloseButtonModal disableRipple onClick={closeModal}>
						Close
						<CancelIcon />
					</StyledCloseButtonModal>
				</StyledDialogHeader>
				<StyledDialogBody>
					{isAmlCheckingSection ? (
						<StyledDialogContent>
							<AmlCheckingSection />
						</StyledDialogContent>
					) : (
						<>
							<StyledDialogContent>
								<Paper elevation={0} className="user-info-action">
									<Grid container>
										<Grid item xs={12}>
											<CompanyForm
												variant={variant}
												setIsDisabledBtn={setIsDisabledBtn}
												onSave={onSave}
												onAmlCheckingBtn={onAmlCheckingBtn}
												setIsValidNumberField={setIsValidNumberField}
											/>
										</Grid>
									</Grid>
								</Paper>
							</StyledDialogContent>
							<StyledDialogActions sx={{ justifyContent: 'space-between' }}>
								<SecondaryButton type="button" onClick={closeModal}>
									Cancel
								</SecondaryButton>
								<CustomButton type="submit" disabled={isDisabledBtn} form="company-form">
									{isValidAccNumberField ? 'Save' : 'Validate'}
								</CustomButton>
							</StyledDialogActions>
						</>
					)}
				</StyledDialogBody>
			</StyledCompanyModal>
		</>
	)
}

export default CompanyModal
