import { useState, useMemo, useEffect, useCallback } from 'react'
import {
	createBrowserRouter,
	createRoutesFromElements,
	Navigate,
	Route,
	RouterProvider,
} from 'react-router-dom'
import { useAppDispatch, useAppSelector } from './hooks/redux'
import LoginPage from './pages/LoginPage'
// import MetricsDashboard from './pages/MetricsDashboardPage'
import { ThemeProvider } from '@mui/material'

import createTheme from '@mui/material/styles/createTheme'
import { PaletteMode } from '@mui/material'
import { getTheme } from './helpers/theme'
import CssBaseline from '@mui/material/CssBaseline'
import './main.scss'
import 'overlayscrollbars/overlayscrollbars.css'
import MetricsDashboardPage from './pages/MetricsDashboardPage'
import CustomModal from './components/modal/CustomModal'
import ReferralLinksPage from './pages/ReferralLinksPage'
import { localStorageData } from './helpers/localStorage'

import { authActionCreators } from './store/actionCreators/authActionCreators'
import ProtectedRoute from './components/structural/ProtectedRoute'
import ProtectedLoginRoute from './components/structural/ProtectedLoginRoute'
import { Notification } from './components/Notification'
import MyCustomersPage from './pages/MyCustomersPage'
import ReportsPage from './pages/ReportsPage'
import CompaniesPage from './pages/CompaniesPage'

function App() {
	const dispatch = useAppDispatch()
	const darkMode = useAppSelector(state => state.theme.darkMode)
	const [mode, setMode] = useState<PaletteMode>('light')
	const token = localStorageData.getData('authData')

	useEffect(() => {
		if (darkMode) {
			setMode('dark')
		} else {
			setMode('light')
		}
	}, [darkMode])

	const theme = useMemo(() => createTheme(getTheme(mode)), [mode])

	const userDetails = useAppSelector(state => state.auth.userDetails)

	const getUserDetails = useCallback(async () => {
		await dispatch(authActionCreators.getUserDetails())
	}, [dispatch])

	useEffect(() => {
		if (token && !userDetails) {
			getUserDetails()
		}
	}, [getUserDetails, token, userDetails])

	const router = createBrowserRouter(
		createRoutesFromElements(
			<Route path="/">
				<Route index element={<Navigate to="/my-customers" />} />
				<Route element={<ProtectedRoute />}>
					<Route path="referral-links" element={<ReferralLinksPage />} />
					<Route path="my-customers" element={<MyCustomersPage />} />
					<Route path="reports" element={<ReportsPage />} />
					<Route path="companies" element={<CompaniesPage />} />
				</Route>
				<Route element={<ProtectedLoginRoute />}>
					<Route path="login" element={<LoginPage />} />
				</Route>
				<Route path="*" element={<Navigate to="/" />} />
				{/* <Route path="/dashboard" element={<MetricsDashboardPage />} /> */}
			</Route>,
		),
	)

	return (
		<ThemeProvider theme={theme}>
			<CssBaseline />
			<RouterProvider router={router} />
			<CustomModal />
			<Notification />
		</ThemeProvider>
	)
}

export default App
