import { createSlice } from '@reduxjs/toolkit'
import { LoadState } from '../../types/commonTypes'
import { CompanyDetails, ValidateCompanyAccNumberResponse } from '../../types/companiesTypes'
import { companiesActionCreators } from '../actionCreators/companiesActionCreators'

type CompaniesState = {
  error: string
  companyList: Array<CompanyDetails>
  getCompanyListLoadState: LoadState

  companyDetails: CompanyDetails | null
  getCompanyDetailsLoadState: LoadState
  accessTokenForAmlCheckingCompany: string

  validateCompanyAccNumberLoadState: LoadState
  accNumberData: ValidateCompanyAccNumberResponse | null
  accNumberAfterValidation: string,
}

const initialState: CompaniesState = {
  error: '',
  companyList: [],
  getCompanyListLoadState: {
    isLoading: false,
    isLoaded: false,
  },

  companyDetails: null,
  getCompanyDetailsLoadState: {
    isLoading: false,
    isLoaded: false,
  },
  accessTokenForAmlCheckingCompany: '',

  validateCompanyAccNumberLoadState: {
    isLoading: false,
    isLoaded: false,
  },
  accNumberAfterValidation: '',
  accNumberData: null
}

export const companiesSlice = createSlice({
  name: 'companies',
  initialState,
  reducers: {
    clearCompanyList: state => {
      state.companyList = []
    },
    clearCompanyDetails: state => {
      state.companyDetails = null
    },
    clearAccessTokenForAmlCheckingCompany: state => {
      state.accessTokenForAmlCheckingCompany = ''
    }
  },
  extraReducers: builder => {
    builder
      .addCase(companiesActionCreators.getCompanyList.pending, state => {
        state.getCompanyListLoadState = {
          isLoading: true,
          isLoaded: false,
        }
        // state.error = undefined
      })
      .addCase(companiesActionCreators.getCompanyList.fulfilled, (state, action) => {
        state.getCompanyListLoadState = {
          isLoading: false,
          isLoaded: true,
        }
        state.companyList = action.payload
      })
      .addCase(companiesActionCreators.getCompanyList.rejected, state => {
        state.getCompanyListLoadState = {
          isLoading: false,
          isLoaded: false,
        }
      })
      .addCase(companiesActionCreators.getCompanyById.pending, state => {
        state.getCompanyDetailsLoadState = {
          isLoading: true,
          isLoaded: false
        }
      })
      .addCase(companiesActionCreators.getCompanyById.fulfilled, (state, action) => {
        state.getCompanyDetailsLoadState = {
          isLoading: false,
          isLoaded: true
        }
        state.companyDetails = action.payload
      })
      .addCase(companiesActionCreators.getCompanyById.rejected, (state, action) => {
        state.getCompanyDetailsLoadState = {
          isLoading: false,
          isLoaded: false
        }
      })

      .addCase(companiesActionCreators.addCompany.fulfilled, (state, action) => {
        state.companyDetails = action.payload
      })
      .addCase(companiesActionCreators.getAmlAccessTokenForCompany.fulfilled, (state, action) => {
        state.accessTokenForAmlCheckingCompany = action.payload.token
      })

      .addCase(companiesActionCreators.validateCompanyAccNumber.pending, state => {
        state.validateCompanyAccNumberLoadState = {
          isLoading: true,
          isLoaded: false
        }
      })
      .addCase(companiesActionCreators.validateCompanyAccNumber.fulfilled, (state, action) => {
        state.validateCompanyAccNumberLoadState = {
          isLoading: false,
          isLoaded: true
        }
        state.accNumberData = action.payload
        state.accNumberAfterValidation = action.meta.arg.accountNumber
      })
      .addCase(companiesActionCreators.validateCompanyAccNumber.rejected, state => {
        state.validateCompanyAccNumberLoadState = {
          isLoading: false,
          isLoaded: false
        }
      })
  },
})

export const { clearCompanyList, clearCompanyDetails, clearAccessTokenForAmlCheckingCompany } = companiesSlice.actions

export default companiesSlice.reducer
