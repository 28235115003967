import SumsubWebSdk from '@sumsub/websdk-react'
import { useAppDispatch, useAppSelector } from '../../../hooks/redux'
import { useEffect } from 'react'
import { companiesActionCreators } from '../../../store/actionCreators/companiesActionCreators'
import { CircularProgress, styled } from '@mui/material'

const StyledLoading = styled('div')(({ theme }) => ({
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	height: '200px',
	padding: '20px 0',
}))

const AmlCheckingSection = () => {
	const dispatch = useAppDispatch()
	const token = useAppSelector(state => state.companies.accessTokenForAmlCheckingCompany)
	const companyDetails = useAppSelector(state => state.companies.companyDetails)

	const goToPendingPage = async (type: string) => {
		console.log('message', type)
		// if (type === 'idCheck.onApplicantSubmitted' || type === 'idCheck.onApplicantResubmitted') {
		//   await dispatch(userActions.getUserAmlList(flowList))
		//   navigate('/pending')
		// }

		// if (type === 'idCheck.applicantReviewComplete') {
		//   setTimeout(() => {
		//     dispatch(userActions.getUserAmlList(flowList))
		//   }, 3000)
		// }
	}

	const goToPage = async (type: string) => {
		console.log('error', type)
		// if (type === 'idCheck.onApplicantSubmitted' || type === 'idCheck.onApplicantResubmitted') {
		//   await dispatch(userActions.getUserAmlList(flowList))
		//   navigate('/pending')
		// }

		// if (type === 'idCheck.applicantReviewComplete') {
		//   setTimeout(() => {
		//     dispatch(userActions.getUserAmlList(flowList))
		//   }, 3000)
		// }
	}

	useEffect(() => {
		companyDetails?.id && dispatch(companiesActionCreators.getAmlAccessTokenForCompany(companyDetails.id))
	}, [companyDetails?.id, dispatch])

	return token ? (
		<SumsubWebSdk
			accessToken={token}
			expirationHandler={() => Promise.resolve(token)}
			onMessage={goToPendingPage}
			// config={{
			//   uiConf: {
			//     customCssStr:
			//       ':root {\n  --black: #24E2A0;\n --grey-darker: #B2B2B2;\n  --border-color: #DBDBDB;\n --primary-color: #221772; \n --success-color: #3171D8; }\n body { font-family: Roboto, sans-serif;}\n #loader { background-color: #fff; padding: 60px 20px; } \n p {\n  \n  font-size: 16px !important;\n  line-height: 24px;\n}\n\nsection {\n  margin: 40px auto;\n}\n\ninput {\n  \n  font-weight: 600;\n  outline: none;\n}\n\nsection.content {\n  background-color: #fff;\n  color: var(--blue-color);\n  padding: 40px 40px 16px;\n  box-shadow: none;\n  border-radius: 6px;\n}\n\n .checkbox .checkmark { margin-top: 4px; } \n\nbutton.submit,\nbutton.back {\n  text-transform: capitalize;\n  border-radius: 6px;\n  height: 48px;\n  padding: 0 30px;\n  font-size: 16px;\n  background-image: none !important;\n  transform: none !important;\n  box-shadow: none !important;\n  transition: all 0.2s linear;\n}\n\nbutton.submit {\n  min-width: 132px;\n  background: none;\n  background-color: var(--primary-color);\n}\n\n.round-icon {\n  background-color: var(--primary-color) !important;\n  background-image: none !important;\n}',
			//   },
			// }}
			onError={goToPage}
		/>
	) : (
		<StyledLoading>
			<CircularProgress />
		</StyledLoading>
		// null
	)
}

export default AmlCheckingSection
