import { Controller, useForm } from 'react-hook-form'
import TextFieldForm from '../../ui/TextFieldForm'
import { Checkbox, FormControlLabel, Grid, styled } from '@mui/material'
import { yupResolver } from '@hookform/resolvers/yup'
import CustomTypography from '../../ui/CustomTypography'
import { useTheme } from '@mui/system'
import React, {
	Dispatch,
	SetStateAction,
	useCallback,
	useEffect,
	useMemo,
} from 'react'
import { isEmpty } from 'lodash'
import { AmlInspectionStatus, CompanyFormType } from '../../../types/companiesTypes'
import SelectFieldForm from '../../ui/SelectFieldForm'
import { useAppDispatch, useAppSelector } from '../../../hooks/redux'
import { companiesYupSchemes } from '../../../helpers/resolverScheme/companiesSchemes'
import { CustomButton } from '../../ui/CustomButton'
import { companiesActionCreators } from '../../../store/actionCreators/companiesActionCreators'

const bankOptions = [
	{
		label: 'CBZ',
		value: 1,
	},
]

const countryOptions = [
	{
		label: 'Zimbabwe',
		value: 240,
	},
]

const StyledCompanyForm = styled('form')(() => ({
	'.currency-title': {
		// paddingLeft: '10px',
		marginTop: '10px',
		marginBottom: '10px',
		'&:first-of-type': {
			marginTop: 0,
		},
	},
	'.t-right': {
		'.MuiFormControl-root': {
			marginBottom: 0,
			'.MuiInputBase-input': {
				textAlign: 'right',
			},
		},
	},
	'.MuiTypography-root': {
		lineHeight: '36px',
	},
}))

type CompanyFormProps = {
	setIsDisabledBtn: Dispatch<SetStateAction<boolean>>
	variant: 'add-company' | 'edit-company'
	onAmlCheckingBtn: () => void
	onSave: (dataForm: CompanyFormType, companyId?: string | number) => void
	setIsValidNumberField: Dispatch<SetStateAction<boolean>>
}

const CompanyForm = ({
	setIsDisabledBtn,
	onSave,
	variant,
	onAmlCheckingBtn,
	setIsValidNumberField,
}: CompanyFormProps) => {
	const theme = useTheme()
	const dispatch = useAppDispatch()

	const {
		companyDetails,
		accNumberData,
		accNumberAfterValidation,
	} = useAppSelector(state => state.companies)

	const isShownAmlCheckingBtn = useMemo(
		() =>
			variant === 'edit-company' &&
			(companyDetails?.amlInspectionStatus === AmlInspectionStatus.Initiated ||
				companyDetails?.amlInspectionStatus === AmlInspectionStatus.Pending ||
				companyDetails?.amlInspectionStatus === AmlInspectionStatus.RejectedWithRetry),
		[companyDetails?.amlInspectionStatus, variant],
	)

	const { handleSubmit, formState, control, setValue, watch, setError, clearErrors } =
		useForm<CompanyFormType>({
			resolver: yupResolver(companiesYupSchemes.companyForm),
		})

	const watchedAccountNumber = watch('accountNumber')

	const isValidAccNumberField = useMemo(() => {
		const currentAccNumber = watchedAccountNumber ? watchedAccountNumber.trim() : ''
		const isValidAccNumberAfter = accNumberData ? accNumberData.isValid : false

		return accNumberAfterValidation === currentAccNumber && isValidAccNumberAfter
	}, [accNumberAfterValidation, accNumberData, watchedAccountNumber])

	useEffect(() => {
		setIsValidNumberField(isValidAccNumberField)
	}, [isValidAccNumberField, setIsValidNumberField])

	const setFormValue = useCallback(() => {
		setValue('bankId', 1)
		setValue('countryId', 240)

		if (variant === 'edit-company' && companyDetails) {
			setValue('companyName', companyDetails.companyName)
			setValue('accountNumber', companyDetails.accountNumber)
			setValue('isActive', companyDetails.isActive)
			setValue('notificationEmail', companyDetails.notificationEmail)
		}
	}, [companyDetails, setValue, variant])

	useEffect(() => {
		setFormValue()
	}, [setFormValue])

	useEffect(() => {
		if (typeof accNumberData?.isValid === 'boolean') {
			if (accNumberData?.isValid) {
				return clearErrors('accountNumber')
			} else {
				return setError('accountNumber', { message: accNumberData?.errorDescription })
			}
		}
	}, [
		accNumberData,
		accNumberData?.errorDescription,
		accNumberData?.isValid,
		clearErrors,
		setError,
	])

	useEffect(() => {
		if (isEmpty(formState.errors)) {
			setIsDisabledBtn(false)
		} else {
			setIsDisabledBtn(true)
		}
	}, [formState, setIsDisabledBtn])

	const onSubmit = (dataForm: CompanyFormType) => {
		if (!isValidAccNumberField) {
			const requestData = {
				accountNumber: dataForm.accountNumber.trim(),
				bankId: dataForm.bankId,
			}
			dispatch(companiesActionCreators.validateCompanyAccNumber(requestData))

			return
		}

		companyDetails ? onSave(dataForm, companyDetails.id) : onSave(dataForm)
	}

	return (
		<StyledCompanyForm id="company-form" onSubmit={handleSubmit(onSubmit)}>
			<Grid container>
				<Grid item md={5} xs={12}>
					<CustomTypography colorText={theme.palette.warning.dark}>
						Name:
					</CustomTypography>
				</Grid>
				<Grid item md={7} xs={12}>
					<div className="t-right">
						<TextFieldForm<CompanyFormType>
							name="companyName"
							control={control}
							type="text"
							inputMode="text"
							autoComplete="off"
							maxLength={64}
							disabled={variant === 'edit-company'}
						/>
					</div>
				</Grid>
				<Grid item md={5} xs={12}>
					<CustomTypography
						colorText={theme.palette.warning.dark}
						className="flex align-items-end h-100"
					>
						Bank Name:
					</CustomTypography>
				</Grid>
				<Grid item md={7} xs={12}>
					<div className="t-right">
						<SelectFieldForm<CompanyFormType>
							name="bankId"
							control={control}
							label=""
							options={bankOptions}
							className="mt-0"
							disabled
						/>
					</div>
				</Grid>
				<Grid item md={5} xs={12}>
					<CustomTypography colorText={theme.palette.warning.dark}>
						Account Number:
					</CustomTypography>
				</Grid>
				<Grid item md={7} xs={12}>
					<div className="t-right">
						<TextFieldForm<CompanyFormType>
							name="accountNumber"
							control={control}
							type="text"
							inputMode="text"
							autoComplete="off"
							maxLength={32}
						/>
					</div>
				</Grid>
				<Grid item md={5} xs={12}>
					<CustomTypography
						colorText={theme.palette.warning.dark}
						className="flex align-items-end h-100"
					>
						Country:
					</CustomTypography>
				</Grid>
				<Grid item md={7} xs={12}>
					<div className="t-right">
						<SelectFieldForm<CompanyFormType>
							name="countryId"
							control={control}
							label=""
							options={countryOptions}
							className="mt-0"
							disabled
						/>
					</div>
				</Grid>
				<Grid item md={5} xs={12}>
					<CustomTypography colorText={theme.palette.warning.dark}>
						Notification Email:
					</CustomTypography>
				</Grid>
				<Grid item md={7} xs={12}>
					<div className="t-right">
						<TextFieldForm<CompanyFormType>
							name="notificationEmail"
							control={control}
							type="text"
							inputMode="text"
							autoComplete="off"
							maxLength={64}
						/>
					</div>
				</Grid>
				{isShownAmlCheckingBtn && (
					<Grid
						item
						md={12}
						// xs={isShownAmlCheckingBtn ? 12 : 0}
						alignItems="center"
						mt={2}
					>
						<CustomButton type="button" onClick={onAmlCheckingBtn}>
							Pass Aml Checking
						</CustomButton>
					</Grid>
				)}
				<Grid item md={5} xs={12} alignSelf="center" mt={2}>
					<CustomTypography colorText={theme.palette.warning.dark}>
						Status:
					</CustomTypography>
				</Grid>
				<Grid item md={7} xs={12} mt={2}>
					<Controller
						name="isActive"
						control={control}
						render={({ field: { onChange, value } }) => (
							<FormControlLabel
								sx={{ display: 'flex', justifyContent: 'end' }}
								control={
									<Checkbox
										onChange={event => {
											onChange(event.target.checked)
										}}
										checked={typeof value === 'undefined' ? false : value}
									/>
								}
								label="Activate"
							/>
						)}
					/>
				</Grid>
			</Grid>
		</StyledCompanyForm>
	)
}

export default CompanyForm
