import CompaniesTableMenuButton from '../../components/companies/table/CompaniesTableMenuButton'
import { AmlInspectionStatus, CompanyDetails } from '../../types/companiesTypes'
import { TableConfig } from '../../types/tableTypes'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'

export const companiesTableConfig = (): TableConfig<CompanyDetails> => {
	return {
		columns: [
			{
				id: 'name',
				header: 'Name',
				contentColumn: item => item.companyName,
				// width: '20%',
				width: {
					minWidth: 'inherit',
					width: 'inherit',
				},
			},
			{
				id: 'bankName',
				header: 'Bank Name',
				contentColumn: () => 'CBZ',
				// width: '20%',
				width: {
					minWidth: 'inherit',
					width: 'inherit',
				},
			},

			{
				id: 'accountNumber',
				header: 'Account Number',
				contentColumn: item => item.accountNumber,
				// width: '20%',
				width: {
					minWidth: 'inherit',
					width: 'inherit',
				},
			},
			{
				id: 'amlInspectionStatus',
				header: 'Aml Inspection Status',
				contentColumn: item => (
					<>
						{item.amlInspectionStatus === AmlInspectionStatus.Verified && (
							<CheckCircleIcon fontSize="small" sx={{ color: '#28E5AA' }} />
						)}
						{AmlInspectionStatus[item.amlInspectionStatus]}
					</>
				),
				width: {
					minWidth: 'inherit',
					width: 'inherit',
				},
			},
			{
				id: 'status',
				header: 'Status',
				contentColumn: item => (item.isActive ? 'Active' : 'Inactive'),
				width: {
					minWidth: 'inherit',
					width: 'inherit',
				},
			},
			{
				id: 'menu',
				header: '',
				contentColumn: item => <CompaniesTableMenuButton companyId={item.id} />,
				// width: '20%',
				width: {
					minWidth: 'inherit',
					width: 'inherit',
				},
			},
		],
	}
}
