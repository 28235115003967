import { useCallback, useEffect, useMemo } from 'react'
import { useAppDispatch, useAppSelector } from '../../hooks/redux'
import useInfiniteScroll from 'react-infinite-scroll-hook'
import { styled, Typography } from '@mui/material'
import Box from '@mui/system/Box'
import Grid from '@mui/material/Grid'
import { CustomButton } from '../../components/ui/CustomButton'
import Stack from '@mui/material/Stack'
import { openGlobalModal } from '../../store/reducers/globalModalSlice'
import { removeReferralLinkList } from '../../store/reducers/referralLinkSlice'
import { companiesActionCreators } from '../../store/actionCreators/companiesActionCreators'
import { CompanyDetails } from '../../types/companiesTypes'
import { companiesTableConfig } from '../../helpers/tableConfigs/companiesTableConfigs'
import CompaniesTable from '../../components/companies/table/CompaniesTable'
import { clearCompanyList } from '../../store/reducers/companiesSlice'

const StyledCompaniesPage = styled(Box)(({ theme }) => ({
	'.section-title': {
		paddingLeft: '16px',
		marginBottom: '17px',
		marginTop: '17px',
		[theme.breakpoints.down('md')]: {
			fontSize: '27px',
			lineHeight: '40px',
			paddingLeft: '10px',
			marginBottom: '10px',
		},
	},

	'.MuiGrid-item.search-wrap': {
		paddingRight: '16px',
		[theme.breakpoints.down('sm')]: {
			paddingRight: 0,
		},
	},

	'.MuiGrid-root': {
		[theme.breakpoints.down('sm')]: {
			marginLeft: '0',
			width: '100%',
			'& > .MuiGrid-item': {
				paddingLeft: '0',
				'.row-btn': {
					justifyContent: 'flex-start',
					flexWrap: 'wrap',
					gap: '10px',
					button: {
						margin: '0',
					},
				},
			},
		},
	},
	'.action-block': {
		marginLeft: 0,
		marginBottom: '24px',
		width: '100%',
	},

	'.row-btn': {
		[theme.breakpoints.down('sm')]: {
			justifyContent: 'space-between',
		},
	},
	'.search-wrap': {
		// minWidth: '330px',
		[theme.breakpoints.down('sm')]: {
			maxWidth: '100%',
			minWidth: '100%',
		},
	},
}))

const CompaniesPage = () => {
	const dispatch = useAppDispatch()

	const { companyList } = useAppSelector(state => state.companies)
	const { getCompanyListLoadState, error } = useAppSelector(state => state.companies)

	useEffect(() => {
		dispatch(companiesActionCreators.getCompanyList())

		return () => {
			dispatch(clearCompanyList())
		}
	}, [dispatch])

	const tableConfig = useMemo(() => {
		return companiesTableConfig()
	}, [])

	const onLoadMore = async () => {
		// const currentOffset = offset + tableData.length
		// const currentParams = getQueryString(
		//   concatenateObjProps({ ...filterList, offset: currentOffset, pageSize })
		// )
		// await dispatch(
		//   worldPayActions.getKwlCardPayments({ queryParams: currentParams, offset: currentOffset })
		// )
	}

	const [sentryRef, { rootRef }] = useInfiniteScroll({
		loading: getCompanyListLoadState.isLoading,
		hasNextPage: false,
		onLoadMore,
		disabled: !!error,
		rootMargin: '0px 0px 400px 0px',
	})

	useEffect(() => {
		return () => {
			dispatch(removeReferralLinkList())
		}
	}, [dispatch])

	const onAddCompany = useCallback(async () => {
		dispatch(openGlobalModal({ variant: 'add-company' }))
	}, [dispatch])

	return (
		<StyledCompaniesPage>
			<Typography variant="h2" className="section-title">
				Companies
			</Typography>
			<Grid className="action-block" container spacing={2}>
				<Grid item sm={12} md={8} lg={7}>
					<Stack className="row-btn" spacing={2} direction="row">
						<CustomButton type="button" onClick={onAddCompany}>
							+ New Company
						</CustomButton>
					</Stack>
				</Grid>
				<Grid className="search-wrap" item sm={12} md={4} lg={5}></Grid>
			</Grid>
			<CompaniesTable<CompanyDetails>
				tableData={companyList}
				sentryRef={sentryRef}
				rootRef={rootRef}
				tableConfig={tableConfig}
				loading={getCompanyListLoadState.isLoading}
				// minTableWidth="360px"
				hasNextPage={false}
			/>
		</StyledCompaniesPage>
	)
}

export default CompaniesPage
